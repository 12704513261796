import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import AuthService from "./services/auth.service";

export default function HomePage() {
  const navigate = useNavigate();
  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (!user) {
      navigate("/accounts/sign-in");
    } else {
      navigate("/user/home");
    }
  }, []);
  return (
    <div>
      <p className="text-white text-center mt-10 font-extrabold text-3xl">yes</p>
    </div>
  );
}
