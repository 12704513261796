const stats = [
    { name: 'Total Uploads', stat: '0' },
    { name: 'Available Invites', stat: '0' },
    { name: 'Total Domains', stat: '0' },
    { name: 'UID', stat: '0' },
  ]
  
  const info = [
    { name: 'MOTD', stat: 'Flybear is cringe' },
    { name: 'Status', stat: 'Verified' },
  ]
  export default function UserStats() {
    return (
      <div>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
          {stats.map((item) => (
            <div key={item.name} className="px-4 py-5 bg-purple-700 shadow rounded-lg overflow-hidden sm:p-6">
              <dt className="text-sm font-medium text-gray-200 truncate">{item.name}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-300">{item.stat}</dd>
            </div>
          ))}
        </dl>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
          {info.map((item) => (
            <div key={item.name} className="px-4 py-5 bg-purple-700 shadow rounded-lg overflow-hidden sm:p-6">
              <dt className="text-sm font-medium text-gray-200 truncate">{item.name}</dt>
              <dd className="mt-1 text-3xl font-medium text-gray-300">{item.stat}</dd>
            </div>
          ))}
        </dl>
      </div>
    )
  }
  