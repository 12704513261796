import { CirclePicker } from "react-color";
import { Button } from "@nextui-org/react";
import { Input } from "@nextui-org/react";
import { useState } from "react";

export default function ConfigGenerator() {
  const [title, setTitle] = useState("");
  const [description, setdescription] = useState("");
  const [author, setAuthor] = useState("");
  const [siteName, setSiteName] = useState("");
  const [siteUrl, setSiteUrl] = useState("");

  const config = {
    title: title,
    description: description,
    author: author,
    siteName: siteName,
    siteUrl: siteUrl,
  };
  const jsonString = JSON.stringify(config);

  return (
    <>
      <div className="flex flex-col items-center justify-center w-full px-3 space-y-4">
        <Input size="lg" status="default" placeholder="Title" width="400px" />
        <Input
          size="lg"
          status="default"
          placeholder="Description"
          width="400px"
        />

        <Input size="lg" status="default" placeholder="Author" width="400px" />

        <Input
          size="lg"
          status="default"
          placeholder="Site Name"
          width="400px"
        />

        <Input
          size="lg"
          status="default"
          placeholder="Site URL"
          width="400px"
        />

        <CirclePicker />
        <div className="flex space-x-4">
          <Button color="success">Save Changes</Button>
          <Button color="gradient">Download Config</Button>
        </div>
        <br></br>
      </div>
    </>
  );
}
