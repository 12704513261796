import { ExclamationIcon } from "@heroicons/react/solid";
import { Button } from "@nextui-org/react";

const domains = [
  {
    name: "still-hate-you.xyz",
    custom: "Public, Owned",
  },
  {
    name: "badsk-cummed.xyz",
    custom: "Public, Owned",
  },
  {
    name: "badsk-has-2-dads.xyz",
    custom: "Public, Owned",
  },
  {
    name: "dxqt.top",
    custom: "Public, Owned",
  },
  // More domains...
];

export default function DomainsList() {
  return (
    <>
      <div className="flex justify-end space-x-2">
        <Button className="mt-3" color="gradient">
          Create Subdomain
        </Button>
        <Button className="mt-3" color="secondary">
          Donate
        </Button>
      </div>
      <div className="mt-2 bg-yellow-300 border-l-4 border-yellow-400 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationIcon
              className="h-5 w-5 text-yellow-900"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700">
              Want to donate/use your own domain?{" "}
              <a
                href="https://discord.dxqt.lol"
                target="_blank"
                className="font-medium underline text-yellow-700 hover:text-yellow-600"
              >
                Open a ticket.
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="mt-2 flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden sm:rounded-lg">
              <table className="min-w-full">
                <thead className="bg-tabledark">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                    >
                      Domain Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                    >
                      Type
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Delete</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-darkgray">
                  {domains.map((domain) => (
                    <tr key={domain.email}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {domain.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {domain.custom}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <a href="#" className="text-purple-300">
                          Delete
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
