import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import HomePage from './components/HomePage';
import DashboardMain from './components/dashboard/DashboardMain';
import DashboardConfig from './components/dashboard/DashboardConfig';
import DashboardDomains from './components/dashboard/DashboardDomains';
import DashboardSettings from './components/dashboard/DashboardSettings';
import Terms from './components/legal/Terms';
import PrivacyPolicy from './components/legal/PrivacyPolicy';

export default function App() {
  return (
    <Router>
        <Routes>
            <Route path='/' exact element={<HomePage/>} />
            <Route path='/accounts/sign-in' element={<Login/>} />
            <Route path='/accounts/sign-up' element={<Register/>} />
            <Route path='/terms' element={<Terms/>} />
            <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
            <Route path='/user/home' element={<DashboardMain/>} />
            <Route path='/user/config' element={<DashboardConfig/>} />
            <Route path='/user/domains' element={<DashboardDomains/>} />
            <Route path='/user/settings' element={<DashboardSettings/>} />
        </Routes>
    </Router>
  );
}
