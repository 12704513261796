// copyright da dxqt

import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import AuthService from "./services/auth.service";

// notifcations
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// loading icon
import { Loading } from "@nextui-org/react";

import HCaptcha from "@hcaptcha/react-hcaptcha";

// animations
import { motion } from "framer-motion";

import { Checkbox } from "@nextui-org/react";

export default function Login() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [invitecode, setInvitecode] = useState("");
  const [selected, setSelected] = useState(false);
  const [locked, setLocked] = useState("");

  // Unlock register button.
  const verifyCaptcha = () => {
    setLocked(true);
  };

  // Register

  const signUp = (e) => {
    // Set button as spinning.
    setLoading(true);
    setLocked(false);

    // Unlock the button.
    setTimeout(() => {
      setLoading(false);
      setLocked(true);
    }, 3000);

    if (!selected) {
      toast.error("You must accept my Terms of Service before signing up.");
    } else {
      e.preventDefault();
      setSuccessful(false);
      
      AuthService.register(username, email, password, invitecode).then(
        (response) => {
          setSuccessful(true);
          toast.success(response.data.message);
          setTimeout(() => {
            navigate("/");
            window.location.reload();
          }, 2000);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          toast.error(resMessage);
          setSuccessful(false);
        }
      );
    }
    
  };

  // Session Login
  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <ToastContainer closeOnClick theme="dark" />
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="bg-dark min-h-full flex flex-col justify-center h-screen sm:px-6 lg:px-8"
      >
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-1 text-center text-4xl font-semibold text-white">
            Sign Up
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Or{" "}
            <Link
              to="/accounts/sign-in"
              className="font-medium text-purple-600 hover:text-purple-500"
            >
              login to your existing account
            </Link>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-darker py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-3" action="#" method="POST">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-300"
                >
                  Username
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    className="appearance-none block w-full px-3 py-2 bg-darkgray text-white rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-300"
                >
                  Email Address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    className="appearance-none block w-full px-3 py-2 bg-darkgray text-white rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-300"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    className="appearance-none block w-full px-3 py-2 bg-darkgray text-white rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Invite Code
                </label>
                <div className="mt-1">
                  <input
                    placeholder="Required."
                    required
                    onChange={(e) => {
                      setInvitecode(e.target.value);
                    }}
                    className="appearance-none block w-full px-3 py-2 bg-darkgray text-white rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-sm flex flex-col space-y-4">
                  <HCaptcha
                    sitekey="adad7a2f-415d-4d5e-b0d5-7cd7c1e4856e"
                    onVerify={verifyCaptcha}
                    onExpire={verifyCaptcha}
                  />
                  <Checkbox
                    size="sm"
                    isSelected={selected}
                    onChange={setSelected}
                    color="gradient"
                    labelColor="warning"
                    defaultSelected={false}
                  >
                    I agree with the Terms of Service.
                  </Checkbox>
                </div>
              </div>

              <div>
                <motion.button
                  type="button"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={signUp}
                  disabled={!locked}
                  className="auto w-full flex justify-center py-2 px-4 rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 focus:outline-none focus:ring-purple-500"
                >
                  {loading ? (
                    <Loading color="white" type="spinner" size="md" />
                  ) : (
                    "Register"
                  )}
                </motion.button>
                <p className="mt-3 text-gray-400">
                  @ 2022 dxqt.{" "}
                  <a className="text-purple-600" href="https://dxqt.lol/terms">
                    Terms of Service
                  </a>{" "}
                  |{" "}
                  <a
                    className="text-purple-600"
                    href="https://dxqt.lol/privacy-policy"
                  >
                    Privacy Policy
                  </a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </motion.div>
    </>
  );
}
