export default function UserSettings() {
  return (
    <>
      <div className="space-x-1 md:space-x-6 flex flex-wrap">
        <form className="space-y-4 divide-y divide-gray-200">
          <div className="space-y-4 divide-y divide-gray-200">
            <div>
              <div>
                <h3 className="text-lg leading-6 font-medium text-white">
                  Change Password
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  You can change your current account password here.
                </p>
              </div>

              <div className="mt-6 gap-y-6 gap-x-4">
                <div className="sm:col-span-4 space-y-1">
                  <div className="sm:col-span-3 space-y-2">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-300"
                    >
                      Current Password
                    </label>
                    <div className="mt-1">
                      <input
                        autoComplete="family-name"
                        className="text-white shadow-sm block text-lg bg-darkgray w-96 focus:outline-none"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3 space-y-2">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-gray-300"
                    >
                      New Password
                    </label>
                    <div className="mt-1">
                      <input
                        autoComplete="family-name"
                        className="text-white shadow-sm block text-lg bg-darkgray w-96 focus:outline-none"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-gray-300"
                    >
                      Confirm New Password
                    </label>
                    <div className="mt-3">
                      <input
                        autoComplete="family-name"
                        className="text-white shadow-sm block text-lg bg-darkgray w-96 focus:outline-none"
                      />
                    </div>
                  </div>
                  <div className="pt-3">
                    <div className="flex">
                      <button
                        type="button"
                        className="bg-purple-600 py-2 px-4 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none"
                      >
                        Save/Update Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <form className="space-y-4 divide-y divide-gray-200">
          <div className="space-y-4 divide-y divide-gray-200">
            <div>
              <div>
                <h3 className="text-lg leading-6 font-medium text-white">
                  Change Email
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  You can change your current account email here.
                </p>
              </div>

              <div className="mt-6 gap-y-6 gap-x-4">
                <div className="sm:col-span-4 space-y-1">
                  <div className="sm:col-span-3 space-y-2">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-300"
                    >
                      New Email
                    </label>
                    <div className="mt-1">
                      <input
                        autoComplete="family-name"
                        className="text-white shadow-sm block text-lg bg-darkgray w-96 focus:outline-none"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-gray-300"
                    >
                      Current Password
                    </label>
                    <div className="mt-3">
                      <input
                        autoComplete="family-name"
                        className="text-white shadow-sm block text-lg bg-darkgray w-96 focus:outline-none"
                      />
                    </div>
                  </div>
                  <div className="pt-3">
                    <div className="flex">
                      <button
                        type="button"
                        className="bg-purple-600 py-2 px-4 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none"
                      >
                        Save/Update Email
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <form className="space-y-4 divide-y divide-gray-200">
          <div className="space-y-4 divide-y divide-gray-200">
            <div>
              <div>
                <h3 className="text-lg leading-6 font-medium text-white">
                  Other Settings
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Your current linked Discord account is: adrea#6661
                </p>
              </div>

              <div className="mt-6 gap-y-6 gap-x-4">
                <div className="sm:col-span-4">
                  <div className="pt-3">
                    <div className="flex space-x-2">
                      <button
                        type="button"
                        className="bg-purple-600 py-2 px-4 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none"
                      >
                        Reset ShareX Token
                      </button>
                      <button
                        type="button"
                        className="bg-purple-600 py-2 px-4 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none"
                      >
                        Join Discord Server
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
